import { config } from "utils/config.utils";
import {
  Translation,
  CollectionPage,
  SinglePage,
  Collection,
  SEOCollection,
} from "utils/strapi/collection";
import { Response, ResponseData, SEOResponseData } from "utils/strapi/response";

import { Filter, formatFilterParams } from "./filtering";
import { PaginationParams, formatPaginationParams } from "./pagination";
import { formatPopulationParams } from "./population";

const STRAPI_FETCH_OPTIONS: RequestInit = {
  headers: { Authorization: `Bearer ${config.strapi_read_token}` },
};

type ReturnData<T> = T extends Translation | CollectionPage
  ? Promise<Response<ResponseData[]>>
  : T extends SinglePage
  ? Promise<Response<ResponseData>>
  : T extends SEOCollection
  ? Promise<Response<SEOResponseData[]>>
  : T extends Collection
  ? Promise<Response<ResponseData | ResponseData[]>>
  : never;

type FetchCollectionProps<T> = {
  collection: T;
  pagination?: PaginationParams;
  filters?: Filter[];
  populateOptions?: string[];
};

export const fetchCollection = async <T extends Collection>({
  collection,
  filters,
  pagination,
  populateOptions,
}: FetchCollectionProps<T>): Promise<ReturnData<T>> => {
  const url = new URL(`${config.strapi_api}/${collection}`);

  const paginationSearchParams = pagination
    ? formatPaginationParams(pagination)
    : [];
  const filtersSearchParams = filters ? formatFilterParams(filters) : [];
  const populateSearchParams = populateOptions
    ? formatPopulationParams(populateOptions)
    : [];

  const searchParams = [
    ...paginationSearchParams,
    ...filtersSearchParams,
    ...populateSearchParams,
  ];

  for (const [key, value] of searchParams) {
    url.searchParams.set(key, value);
  }

  const response = await fetch(url.toString(), STRAPI_FETCH_OPTIONS);

  if (!response.ok) {
    throw new Error(`Unable to retrieve strapi collection: ${collection}`);
  }

  return response.json();
};
