import dayjs from "dayjs";
import type { NextPageContext } from "next";
import { decode } from "universal-base64";
import Cookies from "universal-cookie";

import { config, STAGE } from "utils/config.utils";

const cookieOpts = {
  path: "/",
  domain:
    STAGE !== "prod" &&
    typeof window !== "undefined" &&
    // Exclude localhost or local domains
    window.location.host.split(".").length > 1
      ? `${window.location.host.replace(/:[0-9]+$/, "")}`
      : config.cookie_domain,
  secure: true,
};

export function setCookie(key: string, value: string, expires?: Date) {
  if (typeof window !== "undefined") {
    const cookies = new Cookies();
    cookies.set(key, value, {
      ...cookieOpts,
      domain: cookieOpts.domain || undefined,
      expires,
    });
  }
}

export function removeCookie(key: string) {
  if (typeof window !== "undefined") {
    const cookies = new Cookies();
    cookies.remove(key, {
      ...cookieOpts,
      domain: cookieOpts.domain || undefined,
    });
  }
}

export function getCookie(key: string, context?: NextPageContext) {
  const headers = context?.req?.headers || {};
  const cookies = new Cookies(headers.cookie || undefined);

  return cookies.get(key);
}

export const setToken = (token: string) =>
  setCookie(
    config.session_cookie,
    token,
    dayjs().add(config.session_cookie_expires_in_days, "day").toDate(),
  );

export function getToken(queryToken?: string) {
  if (queryToken) {
    try {
      const tokenJSON = JSON.parse(decode(queryToken));
      const token = tokenJSON?.token || undefined;
      if (token) {
        setCookie(config.session_cookie, token);
      }

      return token;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    getCookie(config.b2b_session_cookie) || getCookie(config.session_cookie)
  );
}
