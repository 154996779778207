import { useEffect } from "react";
import { match, P } from "ts-pattern";

export type RudderstackCustomer = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
};

export type RudderstackProduct = {
  product_id: string;
  sku: string;
  category: string;
  name: string;
  brand?: string;
  variant?: string;
  price?: number;
  quantity?: number;
  coupon?: string;
  position?: number;
  currency?: string;
};

export type RudderstackProductList = {
  products?: RudderstackProduct[];
  list_id?: string;
  category?: string;
};

export type RudderstackOrder = {
  order_id: number;
  checkout_id?: string;
  affiliation?: string;
  value?: number;
  subtotal?: number;
  total?: number;
  revenue?: number;
  shipping?: number;
  tax?: number;
  discount?: number;
  coupon?: string;
  currency?: string;
  products?: RudderstackProduct[];
};

export type RudderstackCoupon = {
  coupond_id: string;
  order_id?: string;
};

export const identify = ({
  id,
  firstName,
  lastName,
  email,
  phone,
}: RudderstackCustomer) => {
  // identify rudder
  window?.rudderanalytics?.identify?.(id, {
    firstName,
    lastName,
    email,
    phone,
  });
};

export const pageView = () =>
  window?.rudderanalytics?.page?.({
    path: location.pathname,
    referrer: document.referrer,
    search: location.search,
    title: document.title,
    url: location.href,
  });

const injectEventId = (action: string, event: Record<string, any>) =>
  match(event)
    .with({ order_id: P.union(P.string, P.number) }, ({ order_id }) => ({
      ...event,
      event_id: `${action}.${order_id}`,
    }))
    .otherwise(() => event);

export const track = (
  action: string,
  props: Record<string, any> = {},
  options: Record<string, any> = {},
) =>
  window?.rudderanalytics?.track?.(
    action,
    injectEventId(action, props),
    options,
  );

export const trackEvent = ({
  action,
  category,
  label,
  value,
}: {
  action: string;
  category: "engagement" | "lead";
  label: string;
  value?: number;
}) => track(action, { category, label, value });

export const trackProductAdded = (product: RudderstackProduct) =>
  track("Product Added", product);

export const trackProductRemoved = (product: RudderstackProduct) =>
  track("Product Removed", product);

export const trackCheckoutStarted = (order: RudderstackOrder) =>
  track("Checkout Started", order);

export const trackCheckoutStepViewed = (step: number) =>
  track("Checkout Step Viewed", { step });

export const trackCheckoutStepCompleted = (step: number) =>
  track("Checkout Step Completed", { step });

export const trackButtonClicked = (name: string) =>
  track("Button Clicked", { name });

export const trackPaymentInfoEntered = (order: RudderstackOrder) =>
  track("Payment Info Entered", order);

export const trackOrderCompleted = (order: RudderstackOrder) => {
  track("Order Completed", order);
};

export const trackProductViewed = (product: RudderstackProduct) =>
  track("Product Viewed", product);

export const trackProductClicked = (product: RudderstackProduct) =>
  track("Product Clicked", product);

export const trackProductListViewed = (productList: RudderstackProductList) =>
  track("Product List Viewed", productList);

export const trackCouponEntered = (coupon: RudderstackCoupon) =>
  track("Coupon Entered", coupon);

export const trackTooltip = (tooltipId: string) =>
  trackEvent({
    category: "engagement",
    action: "display tooltip",
    label: tooltipId,
  });

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useTrack = (f: () => any) => useEffect(f, []);
