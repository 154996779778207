import { useRouter } from "next/router";
import { useEffect } from "react";

import { identify } from "utils/analytics/analytics.utils";
import { getToken } from "utils/cookies.utils";

import { useIdentifyCustomerLazyQuery } from "./identify.query.generated";
import { fromCustomer } from "./mappers/rudderstack-customer.mapper";

export const useIdentifyCustomer = () => {
  const { route } = useRouter();

  const [identityCustomer] = useIdentifyCustomerLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: ({ customer }) => customer && identify(fromCustomer(customer)),
  });

  useEffect(() => {
    const token = getToken();

    if (token) {
      identityCustomer();
    }
  }, [route, identityCustomer]);
};
