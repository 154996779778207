export const DISABLED_PAGINATION_PARAMS = {
  start: 0,
  limit: -1,
  withCount: false,
};

export type PaginationParams = (
  | { page: number; pageSize: number }
  | { start: number; limit: number }
) & { withCount: boolean };

/**
 * @param pagination an object of pagination options.
 * @returns an array of pagination options.
 * @example ({ start: 0, limit: -1, withCount: false }) => [["pagination[start]","0"}], ["pagination[limit]","-1"}], ["pagination[withCount]","false"}]] ]
 */
export const formatPaginationParams = (pagination: PaginationParams) =>
  Object.entries(pagination).map(([key, value]) => [
    `pagination[${key}]`,
    value.toString(),
  ]);
