import type { NextPage } from "next";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { StorageKey, useStorage } from "./storage.utils";

export function withCoupon<TProps>(Component: NextPage<TProps>) {
  return function (props: TProps) {
    const { query } = useRouter();

    const [referralCode, setReferralCode] = useStorage(
      StorageKey.referralCode,
      { expires: Date.now() + 72 * 60 * 60 * 1000 },
    );

    useEffect(() => {
      const queryDiscountCode = query.coupon || query["code-parrain"];
      if (queryDiscountCode && referralCode !== queryDiscountCode) {
        setReferralCode(queryDiscountCode);
      }
    }, [referralCode, query, setReferralCode]);

    return <Component {...props} />;
  };
}
