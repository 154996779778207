import type { NextPage } from "next";
import React from "react";

import { useIdentifyCustomer } from "shared/analytics/useIdentifyCustomer";

export function withIdentifyCustomer<TProps>(Component: NextPage<TProps>) {
  return function (props: any) {
    useIdentifyCustomer();

    return <Component {...props} />;
  };
}
