import { RudderstackCustomer } from "utils/analytics/analytics.utils";

import { IdentifyCustomerFragment } from "../identify.query.generated";

export const fromCustomer = (
  customer: IdentifyCustomerFragment,
): RudderstackCustomer => ({
  id: String(customer.id),
  firstName: customer.firstName || undefined,
  lastName: customer.lastName || undefined,
  phone: customer.phone || undefined,
  email: customer.email || undefined,
});
