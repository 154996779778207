import { createNanoEvents } from "nanoevents";
import type { Dispatch } from "react";
import { useState, useEffect } from "react";

const isBrowser = typeof window !== "undefined";

export enum StorageKey {
  referralCode = "referralCode",
  discountCodeBanner = "discountCodeBanner",
  acceptedCookies = "acceptedCookies",
}

interface UseStorageOptions<TItemValue> {
  defaultValue?: TItemValue;
  expires?: number;
}

interface Events {
  update: (event: { key: StorageKey; value: any; expires?: number }) => void;
}

class UniversalStorage {
  private emitter = createNanoEvents<Events>();
  private storage: Storage | null = null;

  constructor(storage: Storage | null) {
    this.storage = storage;
  }

  on<TEvent extends keyof Events>(event: TEvent, callback: Events[TEvent]) {
    return this.emitter.on(event, callback);
  }

  getItem<TItemValue>(key: StorageKey, defaultValue?: TItemValue) {
    const value = this.getItemWithExpires<TItemValue>(key);

    return value === null ? defaultValue : value;
  }

  setItem<TItemValue>(key: StorageKey, value: TItemValue, expires?: number) {
    if (this.storage) {
      this.storage.setItem(key, JSON.stringify({ value, expires }));
      this.emitter.emit("update", { key, value });
    }
  }

  private getItemWithExpires<TItemValue>(key: string): TItemValue | null {
    if (this.storage) {
      try {
        const data = JSON.parse(this.storage.getItem(key) || "NULL");
        const hasExpired = data.expires && Date.now() > data.expires;
        if (hasExpired) {
          window.localStorage.removeItem(key);
          return null;
        }
        return data.value;
      } catch {
        return null;
      }
    }
    return null;
  }
}

const localStorage = new UniversalStorage(
  isBrowser ? window.localStorage : null,
);

const sessionStorage = new UniversalStorage(
  isBrowser ? window.sessionStorage : null,
);

const getStorage = (key: StorageKey) => {
  // prettier-ignore
  switch (key) {
    case StorageKey.discountCodeBanner: return sessionStorage;
    default: return localStorage;
  }
};

export function useStorage<TItemValue>(
  key: StorageKey,
  options: UseStorageOptions<TItemValue> = {},
) {
  const storage = getStorage(key);
  const [cachedValue, setCachedValue] = useState(options.defaultValue);

  const updater = (value: TItemValue) => {
    setCachedValue(value);
    storage.setItem<TItemValue>(key, value, options.expires);
  };

  useEffect(
    () => {
      const afterRender = storage.getItem<TItemValue>(
        key,
        options.defaultValue,
      );
      if (afterRender !== cachedValue) setCachedValue(afterRender);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const off = storage.on("update", (event) => {
      if (event.key === key && event.value !== cachedValue) {
        setCachedValue(event.value);
      }
    });

    return () => {
      off();
    };
  });

  return [cachedValue, updater] as [TItemValue, Dispatch<TItemValue>];
}

export function useSerializedLocalStorage<T>(key: string) {
  const [value, setValue] = useState<T>(() => {
    const localStorageValue = window.localStorage.getItem(key);

    return localStorageValue ? JSON.parse(localStorageValue) : undefined;
  });

  useEffect(() => {
    if (!value) return;

    window.localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue] as const;
}
