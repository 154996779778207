import type { NormalizedCacheObject } from "@apollo/client";
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  InMemoryCacheConfig,
  from,
  split,
} from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { ApolloProvider } from "@apollo/client/react";
import { RetryLink } from "apollo-link-retry";
// @ts-ignore
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { getMainDefinition } from "apollo-utilities";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { config } from "./config.utils";
import { getToken, removeCookie } from "./cookies.utils";

const isServer = typeof window === "undefined";
let apolloClient: ApolloClient<NormalizedCacheObject>;

const CACHE_CONFIG: InMemoryCacheConfig = {
  typePolicies: {
    orders: {
      fields: {
        order_items: {
          merge: (_, incoming) => incoming,
        },
      },
    },
    B2CSmartphoneCatalog: {
      keyFields: ["catalogId"],
    },
    SmartphoneSubscription: {
      merge: true,
    },
    SmartphoneFamily: {
      merge: true,
    },
    SmartphoneVariant: {
      merge: true,
    },
  },
};

function createApolloClient(queryToken?: string) {
  const hasuraLink = createUploadLink({ uri: config.graphql_url });
  const multipartLink = createUploadLink({
    uri: config.api_v2_graphql_url,
    headers: { "Apollo-Require-Preflight": true },
  });

  // we need send multipart GraphQL requests directly to API_v2
  // see: https://github.com/hasura/graphql-engine/issues/2419
  // try to say it very fast: type.type.type.type...
  const httpLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      const isSingleUpload = definition.variableDefinitions
        ? definition.variableDefinitions.some(
            ({ type }: any) =>
              type.kind === "NonNullType" &&
              type.type.kind === "NamedType" &&
              type.type.name.value === "Upload",
          )
        : false;

      const isMultiUpload = definition.variableDefinitions
        ? definition.variableDefinitions.some(
            ({ type }: any) =>
              type.kind === "ListType" &&
              type.type.kind === "NonNullType" &&
              type.type.type.kind === "NamedType" &&
              type.type.type.name.value === "Upload",
          )
        : false;

      return isSingleUpload || isMultiUpload;
    },
    multipartLink,
    hasuraLink,
  );

  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = getToken(queryToken);
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    // add the authorization to the headers
    operation.setContext({
      headers,
    });

    return forward ? forward(operation) : null;
  });

  // allow to catch JWT Expired errors
  // remove the cookie and reload the page
  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors?.length) {
      const hasJWTExpired = graphQLErrors.some(
        (error) => error?.message?.includes("JWTExpired"),
      );

      if (hasJWTExpired) {
        removeCookie(config.session_cookie);
        removeCookie(config.b2b_session_cookie);
        window.location.reload();
      }
    }
  });

  const retryLink = new RetryLink() as unknown as ApolloLink;

  return new ApolloClient({
    ssrMode: isServer,
    connectToDevTools: !isServer,
    link: from([authMiddleware, retryLink, errorLink, httpLink]),
    cache: new InMemoryCache(CACHE_CONFIG),
    queryDeduplication: true,
  });
}

export function initializeApollo({
  initialState,
  queryToken,
}: { initialState?: Record<string, any>; queryToken?: string } = {}) {
  const _apolloClient = apolloClient ?? createApolloClient(queryToken);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    const previousState = _apolloClient.cache.extract() || {};
    const nextState = {
      ...initialState,
      ...previousState,
      ROOT_QUERY: {
        ...(initialState.ROOT_QUERY || {}),
        ...(previousState.ROOT_QUERY || {}),
      },
    };
    _apolloClient.cache.restore(nextState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

function useQueryToken() {
  const router = useRouter();
  const url = new URL(`http://example.com${router.asPath}`);

  return url.searchParams.get("token") || undefined;
}

export function withApollo<TProps>(Component: NextPage<TProps>) {
  return function (props: any) {
    const queryToken = useQueryToken();
    const pageApolloClient = useMemo(
      () =>
        initializeApollo({
          initialState: props.initialApolloState,
          queryToken,
        }),
      [props.initialApolloState, queryToken],
    );

    return (
      <ApolloProvider client={pageApolloClient}>
        <Component {...props} />
      </ApolloProvider>
    );
  };
}
