// stage can be: dev, staging, prod

import { Configuration } from "shared/AdyenCheckout/useAdyenCheckout";

enum Stage {
  Dev = "dev",
  Staging = "staging",
  Prod = "prod",
}

export const STAGE = process.env.STAGE as Stage;

export const CACHE_ONE_WEEK = 60 * 60 * 24 * 7;
export const CACHE_ONE_DAY = 60 * 60 * 24;
export const CACHE_ONE_HOUR = 60 * 60;
export const CACHE_ONE_MINUTE = 60;

const envConfig = {
  shared: {
    b2b_session_cookie: `${STAGE}_b2b-token-v1`,
    maintenance: process.env.NEXT_PUBLIC_MAINTENANCE === "true" || false,
    stage: STAGE,
    rudderstack_data_plane_url: "https://8m1yxjnp.mobile.club",
    rudderstack_write_key: "1eJ23Bwc0cti9xci8BznXTSFKFm",
    google_optimize: "GTM-593CQV8",
    mapboxPlacesApiUrl: "https://api.mapbox.com/geocoding/v5/mapbox.places/",
    mapboxApiKey:
      "pk.eyJ1IjoibW9iaWxlY2x1YiIsImEiOiJja3Y5bGxrNzU1d2llMm5zN29xMmV0NzIxIn0.7RspTiNv9aKg6cRBuAFUWw",
    session_cookie_expires_in_days: 7,
    adyen: {
      env: "test",
      client_key: "test_V2TLQHDWRJBQTKMX6FR7PPZSTMSUEGKV",
    },
  },
  dev: {
    b2b_url: "http://localhost:4000/business",
    website_url: "http://localhost:4000",
    graphql_url: "http://localhost:8080/v1/graphql",
    api_v2_graphql_url: "http://localhost:4444/graphql",
    cdn_url: "assets.cms.staging.mobile.club",
    api_v2_url: "http://localhost:4444",
    cookie_domain: "localhost",
    session_cookie: "dev_api-token-v2",
    strapi_api: "https://cms.staging.mobile.club/api",
    strapi_read_token:
      "19f769b1eb0df4481fea65895f5ac63c763375161f32b5e904ebb6662967a667a0b82a55f2b65841bb61d6fbd2db1b75ebc2fd32c372b6bf711cc3eae8e0d6e91512498714cc7262166c3a0b7a5c8ec1990241810a82428c3f71afce978071be346c572cfea7cc2a29151888c18d1a104f34def69ef7b76897a9e681dc3b821a",
    google_api_key: "AIzaSyDOb-nTc2wwXWXTpFqX0FVtedEvi6IXrGY",
    one_trust_domain: "4ca026ae-0845-4dd0-8e12-5f7162c68b70-test",
    tink: {
      clientId: "b49c3c36d6f24edba248e91c539d46c1",
      redirectUri: "http://localhost:4000/commander/verification",
      accountCheckAndTransactionReportUrl:
        "https://link.tink.com/1.0/reports/create-report",
    },
  },
  staging: {
    b2b_url: "https://b2b.staging.mobile.club/",
    website_url: `https://${
      process.env.VERCEL_URL || "www.staging.mobile.club"
    }`,
    graphql_url: "https://graphql.staging.mobile.club/v1/graphql",
    api_v2_graphql_url: "https://api-v2.staging.mobile.club/graphql",
    api_v2_url: "https://api-v2.staging.mobile.club",
    cdn_url: "assets.cms.staging.mobile.club",
    cookie_domain: ".staging.mobile.club",
    session_cookie: "staging_api-token-v3",
    strapi_api: "https://cms.staging.mobile.club/api",
    strapi_read_token:
      "19f769b1eb0df4481fea65895f5ac63c763375161f32b5e904ebb6662967a667a0b82a55f2b65841bb61d6fbd2db1b75ebc2fd32c372b6bf711cc3eae8e0d6e91512498714cc7262166c3a0b7a5c8ec1990241810a82428c3f71afce978071be346c572cfea7cc2a29151888c18d1a104f34def69ef7b76897a9e681dc3b821a",
    google_api_key: "AIzaSyAZ8hH8i7rD3TqurSVZ4n1k8v6RXt2Pdu8",
    one_trust_domain: "4ca026ae-0845-4dd0-8e12-5f7162c68b70-test",
    tink: {
      clientId: "b49c3c36d6f24edba248e91c539d46c1",
      redirectUri: "https://www.staging.mobile.club/commander/verification",
      accountCheckAndTransactionReportUrl:
        "https://link.tink.com/1.0/reports/create-report",
    },
  },
  prod: {
    b2b_url: "https://b2b.mobile.club/",
    website_url: "https://www.mobile.club",
    graphql_url: "https://graphql.mobile.club/v1/graphql",
    api_v2_graphql_url: "https://api-v2.mobile.club/graphql",
    api_v2_url: "https://api-v2.mobile.club",
    rudderstack_write_key: "1eJ0DzTgVCikvTKdLN9ngyM7L7i",
    session_cookie: "prod_api-token-v2",
    cookie_domain: "www.mobile.club",
    cdn_url: "assets.cms.mobile.club",
    google_optimize: "GTM-NPFGTQK",
    strapi_api: "https://cms.mobile.club/api",
    strapi_read_token:
      "4bca22f82bd2e1927c0d5962fdce1befda2f05259f523ea698976fac4e6ab280e66a8b184b9ecb890554909236a790048575aeb79f3516f6ef30f7b27609cee16c3c4a938d4ab487ea356e55d4202b89d86a6f1f017cb986a8e849c2590da1817eaaea2af9d1e555c7c17e06b077550f0eef69f1e71de2cefec747da6a15b1e1",
    google_api_key: "AIzaSyAZ8hH8i7rD3TqurSVZ4n1k8v6RXt2Pdu8",
    one_trust_domain: "4ca026ae-0845-4dd0-8e12-5f7162c68b70",
    adyen: {
      env: "live",
      client_key: "live_NC54MVGR5BF2ZPDSS4O5HHX45MKCNGZ6",
    },
    tink: {
      clientId: "b49c3c36d6f24edba248e91c539d46c1",
      redirectUri: "https://www.mobile.club/commander/verification",
      accountCheckAndTransactionReportUrl:
        "https://link.tink.com/1.0/reports/create-report",
    },
  },
};

export const config = {
  ...envConfig.shared,
  ...envConfig[STAGE],
};

export const ADYEN_CHECKOUT_CONFIGURATION: Configuration = {
  environment: config.adyen.env,
  clientKey: config.adyen.client_key,
  locale: "fr-FR",
  paymentMethodsConfiguration: {
    card: {
      hasHolderName: true,
      holderNameRequired: true,
      brands: ["cartebancaire", "mc", "visa"],
    },
    bcmc: {
      hasHolderName: true,
      holderNameRequired: true,
    },
  },
};
