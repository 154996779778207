type UnaryOperator =
  | "$eq"
  | "$ne"
  | "$lt"
  | "$lte"
  | "$gt"
  | "$gte"
  | "$null"
  | "$notNull"
  | "$startsWith"
  | "$endsWith";

type AssociativeOperator =
  | "$in"
  | "$notIn"
  | "$contains"
  | "$notContains"
  | "$containsi"
  | "$notContainsi"
  | "$between";

type BinaryOperator = "$or" | "$and";

type UnaryFilter = {
  field: string;
  operator: UnaryOperator;
  value: string;
};

type BinaryFilter = {
  operator: BinaryOperator;
  filters: UnaryFilter[];
};

type AssociativeFilter = {
  field: string;
  operator: AssociativeOperator;
  value: string[];
};

export type Filter = UnaryFilter | BinaryFilter | AssociativeFilter;

const isUnaryFilter = (filter: Filter): filter is UnaryFilter =>
  "field" in filter &&
  "operator" in filter &&
  "value" in filter &&
  !Array.isArray(filter.value);

const isBinaryFilter = (filter: Filter): filter is BinaryFilter =>
  "operator" in filter && "filters" in filter;

const isAssociativeFilter = (filter: Filter): filter is AssociativeFilter =>
  "field" in filter &&
  "operator" in filter &&
  "value" in filter &&
  Array.isArray(filter.value);

const formatUnaryFilter = (
  { field, operator, value }: UnaryFilter,
  index = 0,
): [string, string] => [`filters[${field}][${operator}][${index}]`, value];

const formatBinaryFilter = ({
  filters,
  operator: binaryOperator,
}: BinaryFilter): [string, string][] =>
  filters.map(({ field, operator: unaryOperator, value }, index) => [
    `filters[${binaryOperator}][${index}][${field}][${unaryOperator}]`,
    value,
  ]);

const formatAssociativeFilter = ({
  field,
  operator: associativeOperator,
  value: associativeValue,
}: AssociativeFilter): [string, string][] =>
  associativeValue.map((value, index) => [
    `filters[${field}][${associativeOperator}][${index}]`,
    value,
  ]);

const formatFilter = (filter: Filter, index: number) => {
  if (isUnaryFilter(filter)) {
    return [formatUnaryFilter(filter, index)];
  }

  if (isAssociativeFilter(filter)) {
    return formatAssociativeFilter(filter);
  }

  if (isBinaryFilter(filter)) {
    return formatBinaryFilter(filter);
  }

  return [];
};

export const formatFilterParams = (
  [filter, ...rest]: Filter[],
  index = 0,
): [string, string][] =>
  !!filter
    ? [...formatFilter(filter, index), ...formatFilterParams(rest, index + 1)]
    : [];
