import "styles/global.css";
import "styles/fonts.css";

import { NextPage } from "next";
import type { AppProps } from "next/app";
import { Router, useRouter } from "next/router";
import React, { ReactElement, ReactNode, useEffect } from "react";

import { useTrackAdwords } from "shared/analytics/useTrackAdwords";
import { useTrackMicrosoftBingAds } from "shared/analytics/useTrackMicrosoftBingAds";
import { useTrackUtmSource } from "shared/analytics/useTrackUtmSource";
import { pageView } from "utils/analytics/analytics.utils";
import { Rudderstack } from "utils/analytics/rudderstack";
import { config } from "utils/config.utils";

import Maintenance from "./maintenance";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, pageProps: P) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();

  // Disable prefetching on all site
  useEffect(() => {
    router.prefetch = async () => {};
  }, [router]);

  useEffect(() => {
    // track initial page view
    pageView();

    // track page views on route change
    Router.events.on("routeChangeComplete", pageView);

    return () => {
      Router.events.off("routeChangeComplete", pageView);
    };
  }, []);

  useTrackAdwords();
  useTrackMicrosoftBingAds();
  useTrackUtmSource();

  /**
   * Persist layout between pages
   * @see  https://nextjs.org/docs/basic-features/layouts
   */
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      {config.maintenance ? (
        <Maintenance />
      ) : (
        <>
          <Rudderstack />
          {getLayout(<Component {...pageProps} />, pageProps)}
        </>
      )}
    </>
  );
}
