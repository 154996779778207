import { NextPageWithLayout } from "pages/_app";

import { withApollo } from "./with-apollo.utils";
import { withCoupon } from "./with-coupon.utils";
import { withI18n } from "./with-i18n.utils";
import { withIdentifyCustomer } from "./with-identify-customer.utils";

type Hoc<TProps> = (
  Component: NextPageWithLayout<TProps>,
) => NextPageWithLayout<TProps>;

export function composeHocs<TProps>(
  Component: NextPageWithLayout<TProps>,
  hocs: Array<Hoc<TProps>>,
) {
  return hocs
    .reverse()
    .reduce<NextPageWithLayout<TProps>>(
      (result, hoc) => hoc(result),
      Component,
    );
}

export function withApp<TProps>(
  AppPage: NextPageWithLayout<TProps>,
  extraHOCs: Array<Hoc<TProps>> = [],
) {
  const ComposedPage = composeHocs(AppPage, [
    withI18n,
    withApollo,
    withIdentifyCustomer,
    withCoupon,
    ...extraHOCs,
  ]);

  if ("getInitialProps" in AppPage) {
    ComposedPage.getInitialProps = AppPage.getInitialProps;
  }

  if ("getLayout" in AppPage) {
    ComposedPage.getLayout = AppPage.getLayout;
  }

  return ComposedPage;
}
