import type { NextSeoProps } from "next-seo";

import MobileClubLogo from "assets/logo-mobile-club.svg";
import { CACHE_ONE_WEEK } from "utils/config.utils";
import { withApp } from "utils/with-app.utils";
import { withSeo } from "utils/with-seo.utils";

const MaintenancePage = () => {
  return (
    <div className="flex relative flex-col gap-9 justify-center items-center min-h-screen bg-yellow">
      <MobileClubLogo width={200} height={101} fill="all" />
      <span className="text-6xl font-black text-center lg:text-7xl">
        Nous revenons bientot.
      </span>
      <div className="text-2xl text-center lg:text-4xl">
        Le temps de faire quelques modifications !
      </div>
    </div>
  );
};

export async function getStaticProps() {
  const seo: NextSeoProps = { noindex: true };

  return {
    props: { seo },
    revalidate: CACHE_ONE_WEEK,
  };
}

export default withApp(MaintenancePage, [withSeo]);
