import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { setCookie } from "utils/cookies.utils";

const UTM_SOURCE_COOKIE_DAYS_TTL = 30;

export const useTrackUtmSource = () => {
  const {
    query: { utm_source: utmSource },
  } = useRouter();

  useEffect(() => {
    if (typeof utmSource === "string") {
      setCookie(
        "utmSource",
        utmSource,
        dayjs().add(UTM_SOURCE_COOKIE_DAYS_TTL, "days").toDate(),
      );
    }
  }, [utmSource]);
};
