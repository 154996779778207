import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { setCookie } from "utils/cookies.utils";

const MSCLKID_COOKIE_DAYS_TTL = 30;

export const useTrackMicrosoftBingAds = () => {
  const {
    query: { msclkid },
  } = useRouter();

  useEffect(() => {
    if (typeof msclkid === "string") {
      setCookie(
        "msclkid",
        msclkid,
        dayjs().add(MSCLKID_COOKIE_DAYS_TTL, "days").toDate(),
      );
    }
  }, [msclkid]);
};
