import "dayjs/locale/fr";
import dayjs from "dayjs";
import type { NextPage } from "next";
import React from "react";
import template from "templite";

import { fetchCollection } from "utils/strapi/api-client";
import { DISABLED_PAGINATION_PARAMS } from "utils/strapi/pagination";
import { TranslationTable } from "utils/strapi/translation-table";

type TKey = string | number | Array<string | number>;
type TVariables = Record<string, any>;
type TOptions = { html?: boolean; fallback?: TKey };

export class I18n {
  constructor(private readonly translations: Record<string, string>) {}

  t(
    key: TKey,
    variables?: TVariables,
    options?: { html?: false; fallback?: TKey },
  ): string;

  t(
    key: TKey,
    variables: TVariables,
    options: { html?: true; fallback?: TKey },
  ): React.ReactNode;

  t(
    key: TKey,
    variables: TVariables = {},
    options: TOptions = { html: false },
  ) {
    const keyAsString = Array.isArray(key) ? key.join(".") : key;

    // We do not have the translations for this specific key
    // just display the key itself
    if (typeof this.translations[keyAsString] === "undefined") {
      // Throw an error if we are in production mode else warn
      if (typeof window === "undefined" && process.exit) {
        console.warn(`Missing translation for key <${keyAsString}>`);
        process.exit(1);
      }

      return options.fallback || keyAsString;
    }

    // Compile variables into translation
    // check that we need to add variables into it before compiling the template (perf)
    const translation =
      Object.keys(variables).length > 0
        ? template(this.translations[keyAsString], variables)
        : this.translations[keyAsString];

    // Translation may have HTML into it
    return options.html ? (
      <span dangerouslySetInnerHTML={{ __html: translation }} />
    ) : (
      translation
    );
  }

  reverse(text: string) {
    const match = Object.entries(this.translations).find(
      ([, value]) => value === text,
    );

    if (!match) {
      throw new Error(`Could not reverse [${text}] to an i18n key`);
    }

    return match[0];
  }

  deviceParticle({ model, brand }: { model: string; brand: string }) {
    return (brand === "apple" ? "l'" : `le `) + this.t(["devices", model]);
  }
}

const I18nContext = React.createContext(new I18n({}));

export function useI18n() {
  return React.useContext(I18nContext);
}

export function withI18n<TProps>(Component: NextPage<TProps>) {
  return function (props: any) {
    const i18n = new I18n(props.translations || {});
    dayjs.locale("fr");

    return (
      <I18nContext.Provider value={i18n}>
        <Component {...props} />
      </I18nContext.Provider>
    );
  };
}

const DEFAULT_TRANSLATIONS: TranslationTable[] = [
  "color",
  "common",
  "cookie",
  "device",
  "error-page",
  "footer",
  "insurance-modal",
  "navbar",
  "seo",
];

export async function fetchTranslations(
  translationTables: TranslationTable | TranslationTable[] = [],
  { withDefaults = true } = {},
) {
  const translationTableArray = Array.isArray(translationTables)
    ? translationTables
    : [translationTables];
  const selectedTranslationTable = withDefaults
    ? [...DEFAULT_TRANSLATIONS, ...translationTableArray]
    : translationTableArray;

  const collections = await fetchCollection({
    collection: "translations",
    pagination: DISABLED_PAGINATION_PARAMS,
    filters: [
      {
        field: "table",
        operator: "$in",
        value: selectedTranslationTable,
      },
    ],
  });

  const translations = collections.data
    .flat()
    .reduce<Record<string, string>>((result, { attributes }) => {
      if (!("key" in attributes) && !("value" in attributes)) {
        throw new Error(`Malformed translation ${attributes}`);
      }

      if (attributes.key in result) {
        throw new Error(`Duplicated i18n key [${attributes.key}]`);
      }

      return {
        ...result,
        [attributes.key]: !!attributes.value
          ? attributes.value
          : attributes.key,
      };
    }, {});

  return translations;
}
