import * as Types from '../../../graphql.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type IdentifyCustomerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IdentifyCustomerQuery = { __typename?: 'query_root', customer?: Types.Maybe<(
    { __typename?: 'CustomerMetadata' }
    & IdentifyCustomerFragment
  )> };

export type IdentifyCustomerFragment = { __typename?: 'CustomerMetadata', id: number, firstName?: Types.Maybe<string>, lastName?: Types.Maybe<string>, email?: Types.Maybe<string>, phone?: Types.Maybe<string> };

export const IdentifyCustomerFragmentDoc = gql`
    fragment IdentifyCustomer on CustomerMetadata {
  id
  firstName
  lastName
  email
  phone
}
    `;
export const IdentifyCustomerDocument = gql`
    query identifyCustomer {
  customer: nest_getCustomerMetadata {
    ...IdentifyCustomer
  }
}
    ${IdentifyCustomerFragmentDoc}`;
export function useIdentifyCustomerQuery(baseOptions?: Apollo.QueryHookOptions<IdentifyCustomerQuery, IdentifyCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IdentifyCustomerQuery, IdentifyCustomerQueryVariables>(IdentifyCustomerDocument, options);
      }
export function useIdentifyCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IdentifyCustomerQuery, IdentifyCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IdentifyCustomerQuery, IdentifyCustomerQueryVariables>(IdentifyCustomerDocument, options);
        }
export type IdentifyCustomerQueryHookResult = ReturnType<typeof useIdentifyCustomerQuery>;
export type IdentifyCustomerLazyQueryHookResult = ReturnType<typeof useIdentifyCustomerLazyQuery>;
export type IdentifyCustomerQueryResult = Apollo.QueryResult<IdentifyCustomerQuery, IdentifyCustomerQueryVariables>;