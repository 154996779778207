import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { setCookie } from "utils/cookies.utils";

const GCLID_COOKIE_DAYS_TTL = 30;

export const useTrackAdwords = () => {
  const {
    query: { gclid },
  } = useRouter();

  useEffect(() => {
    if (typeof gclid === "string") {
      setCookie(
        "gclid",
        gclid,
        dayjs().add(GCLID_COOKIE_DAYS_TTL, "days").toDate(),
      );
    }
  }, [gclid]);
};
